<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>优惠券</el-breadcrumb-item>
            <el-breadcrumb-item>新增优惠券</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="img-box">
         <el-row class="img-item cash-coupon">
            <el-row class="top">
               <h6>代金券</h6>
               <p>获得金额随机的优惠券增加活动趣味性</p>
            </el-row>
            <el-row class="btm">
               <el-button class="bg-gradient" @click="handleJump('/add_coupon/CASHCOUPON')" type="primary">立即添加</el-button>
            </el-row>
         </el-row>
         <el-row class="img-item exchange-coupon">
            <el-row class="top">
               <h6>兑换券</h6>
               <p>用于兑换指定商品吸引顾客消费</p>
            </el-row>
            <el-row class="btm">
               <el-button class="bg-gradient"  @click="handleJump('/add_coupon/EXCHANGECOUPON')" type="primary">立即添加</el-button>
            </el-row>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   export default {
      methods: {
         handleJump(path){
            this.$router.push({ path,query: { action: 'add' }} )
         }
      }
   }
</script>

<style lang="scss" scoped>
   .img-box{
      padding: 80px 0; text-align: center; display: flex;
      justify-content: center;
      .img-item{
         width: 260px; height: 330px; box-shadow: 0 0 5px #999999;
         border-radius: 12px; overflow: hidden; margin: 0 30px;
         .top{
            height: 165px; color: #FFFFFF; padding: 34px 0 0 0;
            h6{ font-size: 24px; margin-bottom: 30px}
            p{ width: 132px; margin: 0 auto }
         }
         .btm{ height: 166px; line-height: 166px }
      }
      .cash-coupon .top{ background: #e14d5f }
      .exchange-coupon .top{ background: #f4b665 }
   }
</style>